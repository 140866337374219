<template>
  <Header />
  <Mast />
  <Services />
  <OurProcess />
  <Values />
  <Work />
  <Speak />
  <Contact />
  <Footer />
</template>

<script>
import Header from './components/Header.vue';
import Mast from './components/Mast.vue';
import Services from './components/Services.vue';
import OurProcess from './components/OurProcess.vue';
import Work from './components/Work.vue';
import Values from './components/Values.vue';
import Speak from './components/Speak.vue';
import Contact from './components/Contact.vue';
import Footer from './components/Footer.vue';

import './styles/styles.scss';

export default {
  name: 'App',
  components: {
    Header,
    Mast,
    OurProcess,
    Services,
    Work,
    Values,
    Speak,
    Contact,
    Footer
  },
}
</script>