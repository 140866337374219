<template>
  <div id="mast" class="container-fluid">
    <div id="intro">
      <div v-if="this.mobile" id="tagline-container-mobile">
        <!-- <img src="../assets/tagline-mobile.png" class="img-fluid" alt="From Startups To Fintech"> -->

        <div id="tagline-container-mobile-shapes">
          <img id="line-shape" src="../assets/line.png" alt="line shape" />
          <img
            id="circle-shape"
            src="../assets/circles.png"
            class="img-fluid"
            alt="circle shape"
          />
          <img id="x-shape" src="../assets/x.png" class="img-fluid" alt="x shape" />
          <img id="triangle-shape" src="../assets/triangle.png" alt="triangle shape" />
        </div>
      </div>

      <h1>
        From Start<span class="muted">(ups)</span> to Fin<span class="muted">(tech)</span>
      </h1>

      <p>
        We are an agile team of software developers, designers, DevOps, project managers
        and QA testers, with countless projects under our collective belts.
        <span class="mt-3 d-block" v-if="mobile"></span>
        No matter the size of your project, we employ proven methodologies every step of
        the process to produce trusted and modern solutions.
      </p>

      <div id="mast-btn-wrapper">
        <router-link :to="{ path: '/', hash: '#services' }">
          <a @mouseenter="mouseenterBtn" role="button" class="btn btn-lmno">
            Our Services
          </a>
        </router-link>

        <router-link :to="{ path: '/', hash: '#contact' }">
          <a class="secondary-link">Chat with us</a>
        </router-link>
      </div>
    </div>

    <div id="mast-image" class="d-none d-lg-block">
      <div id="code-block-img">
        <div id="shapes-container">
          <img
            id="line-shape"
            src="../assets/line.png"
            class="img-fluid"
            alt="line shape"
          />
          <img
            id="circle-shape"
            src="../assets/circles.png"
            class="img-fluid"
            alt="circle shape"
          />
          <img id="x-shape" src="../assets/x.png" class="img-fluid" alt="x shape" />
          <img
            id="triangle-shape"
            src="../assets/triangle.png"
            class="img-fluid"
            alt="triangle shape"
          />
        </div>

        <img src="../assets/left-angle.png" class="img-fluid" alt="Shapes" />
        <img src="../assets/code-dot.png" class="code-dot img-fluid" alt="..." />
        <img src="../assets/code-dot.png" class="code-dot img-fluid" alt="..." />
        <img src="../assets/code-dot.png" class="code-dot img-fluid" alt="..." />
        <img src="../assets/right-angle.png" class="img-fluid" alt="Shapes" />
      </div>
    </div>
  </div>
</template>

<script>
import { isMobile } from "../utils/mobile";
import anime from "animejs";

export default {
  name: "Mast",

  data: function () {
    return {
      mobile: false,
    };
  },

  mounted() {
    if (this.mobile) {
      this.mobileShapes();
    } else {
      this.randomValuesDots();
      this.followShapes();
    }
  },

  created() {
    this.mobile = isMobile();
  },

  methods: {
    mobileShapes() {
      anime({
        targets: "#tagline-container-mobile-shapes img",
        translateY: function () {
          return anime.random(0, -20);
        },
        translateX: function () {
          return anime.random(0, -20);
        },
        easing: "easeInOutQuad",
        duration: 750,
        complete: this.mobileShapes,
        delay: anime.stagger(200),
      });
    },

    followShapes() {
      anime({
        targets: "#shapes-container img",
        translateY: function () {
          return anime.random(0, -20);
        },
        translateX: function () {
          return anime.random(0, -20);
        },
        easing: "easeInOutQuad",
        duration: 750,
        complete: this.followShapes,
        delay: anime.stagger(200),
      });
    },

    randomValuesDots() {
      anime({
        targets: ".code-dot",
        translateY: function () {
          return anime.random(20, -20);
        },
        easing: "easeInOutQuad",
        duration: 550,
        complete: this.randomValuesDots,
        delay: anime.stagger(200),
      });
    },

    mouseenterBtn() {
      //    anime({
      //     targets: '#intro .btn-lmno',
      //     backgroundColor: '#ff2525',
      //     duration: 750,
      //     easing: 'cubicBezier(.5, .05, .1, .3)'
      // });
    },
  },
};
</script>
