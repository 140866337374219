// Mobile Utilities

const isMobile = () => { 
    if (screen.width <= 780) {  
        return true 
    } 
    else  { return false }
}


export { 
    isMobile 
}