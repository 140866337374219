<template>

 <section id="what-we-speak">

<div class="container-fluid">


 <div id="shapes-section-1">
      <img src="../assets/circles.png" class="img-fluid" alt="circle shape">
      <img src="../assets/x.png" class="img-fluid" alt="x shape">
      <img src="../assets/triangle.png" class="img-fluid" alt="triangle shape">
   </div>


  <div class="section-copy">
    <h3>What We Speak</h3>
    <h5>our favorite technologies</h5>

    <div class="line-sep">
      <img src="../assets/line.png" alt="Line">
    </div>
  </div>
</div>
<div id="speak-content-wrapper" class="container-fluid">

   <div id="code-editor">
     <div class="code"></div>
   </div>

    <div id="programming-languages">
      <i class="devicon-javascript-plain"></i>
      <i class="devicon-nodejs-plain"></i>
      <i class="devicon-typescript-plain"></i>
      <i class="devicon-react-plain"></i>
      <i class="devicon-vuejs-plain"></i>
      <i class="devicon-angularjs-plain"></i>
      <i class="devicon-python-plain"></i>
      <i class="devicon-django-plain"></i>
      <i class="devicon-go-plain"></i>
      <i class="devicon-html5-plain"></i>
      <i class="devicon-css3-plain"></i>
      <i class="devicon-webpack-plain"></i>
      <i class="devicon-sass-plain"></i>
      <i class="devicon-mysql-plain"></i>
      <i class="devicon-mongodb-plain"></i>
      <i class="devicon-postgresql-plain"></i>
      <i class="devicon-amazonwebservices-original"></i>
      <i class="devicon-docker-plain"></i>
   </div>
   
</div>

 </section>      

</template>

<script>

import anime from 'animejs';
import Typed from 'typed.js';
import { isMobile } from '../utils/mobile';

// import $ from 'jquery';

export default {
  name: 'Speak',

  data() {
    return {
      mobile: false,
      hasAnimated: false,
    }
  },

  mounted() {
    this.animateEditor();
    this.mobile = isMobile();
  },


  methods: {

  animateEditor() {
    var io = new IntersectionObserver(
      entries => {
        entries[0].isIntersecting && !isMobile() && !this.hasAnimated ? this.codeEditorCode() : '';
      }, {}
    );
    const elem = document.getElementById('what-we-speak');
    io.observe(elem);
  },

  resetEditor() {

      anime({
          targets: '#what-we-speak #code-editor',
          backgroundPositionY: '100vh',
          easing: 'easeInOutQuad',
          duration: 800,
          loop: false
        });
      
    },
 
    codeEditorCode() {

    this.hasAnimated = true;

    
 

      anime({
        targets: '#what-we-speak #code-editor',
        backgroundPositionY: '0vh',
        easing: 'easeInOutQuad',
        duration: 800,
        loop: false
      });

      const codeExamples = [
        `
          <span class="code-blue">import</span> { <span class="code-green">hello</span> } from <span class="code-gray">'elemenojs'</span>;
          <br><br><span class="code-red" style="font-style="italic">const</span> <span class="code-green">elemeno</span> <span class="code-gray">=</span> (<span class="code-green">name</span>) <span class="code-gray">=</span> {
          <br><span class="indent"></span><span class="code-green">hello</span>(<span class="code-green">name</span>);
          <br>}`,          

        `<span class="code-red">package</span> <span class="code-green">main</span>
          <br><br><span class="code-red">import</span> <span class="code-yellow">"fmt"</span>
          <br><span class="code-red">func</span> <span class="code-green">main</span>() {
          <br><span class="indent"><span class="code-red">fmt</span><span class="code-blue">.</span><span class="code-red">Println</span>(<span class="code-yellow">"Hello, World"</span>)
          <br>}`,


          `<span class="code-red">import</span> <span class="code-teal">cmath</span>

          <br><br><span class="code-teal">a</span> <span class="code-red">=</span> <span class="code-blue">1</span>
          <br><span class="code-teal">b</span> <span class="code-red">=</span> <span class="code-blue">5</span>

          <br><br><span class="code-teal">d</span> <span class="code-red">=</span> (<span class="code-green">b</span><span class="code-red">**</span><span class="code-blue">2</span>) <span class="code-red">-</span> (<span class="code-blue">4</span><span class="code-red">*</span><span class="code-green">a</span><span class="code-red">*</span><span class="code-green">c</span>)

          <br><br><span class="code-green">sol1</span> <span class="code-red">=</span> (<span class="code-red">-</span><span class="code-green">b</span><span class="code-red">-</span><span class="code-green">cmath</span>.<span class="code-blue">sqrt</span>(<span class="code-green">d</span>))<span class="code-red">/</span>(<span class="code-blue>">2</span><span class="code-red">*</span><span class="code-green">a</span>)
          <br><span class="code-green">sol2</span> <span class="code-red">=</span> (<span class="code-red">-</span><span class="code-green">b</span><span class="code-red">+</span><span class="code-green">cmath</span>.<span class="code-blue">sqrt</span>(<span class="code-green">d</span>))<span class="code-red">/</span>(<span class="code-blue">2</span><span class="code-red">*</span><span class="code-green">a</span>)

          <br><br>print(<span class="code-yellow">'The solution are {0} and {1}'</span>.<span class="code-blue">format</span>(<span class="code-green">sol1</span>,<span class="code-green">sol2</span>))
          `,

          `
          <span class="code-green">interface User</span> {
          <br><span class="indent"><span class="code-green">name</span>: <span class="code-white">string</span>;</span>
          <br>}

          <br><br><span class="code-red">class</span> <span class="code-green">UserAccount</span> {
          <br><span class="indent"><span class="code-blue">name</span>: <span class="code-green">string</span>;</span>

            <br><br><span class="indent"><span class="code-blue">constructor</span>(<span class="code-green">name</span>: <span class="code-green">string</span>) {</span>
              <br><span class="indentx2"><span class="code-red">this</span>.<span class="code-blue">name</span> <span class="code-red">=</span> name;</span>
            <br><span class="indent">}</span>
          <br>}

          <br><br><span class="code-red">const</span> <span class="code-green">user</span>: <span class="code-green">User</span> <span class="code-red">=</span> <span class="code-red">new</span> <span class="code-green">UserAccount</span>(<span class="code-yellow">"Elemeno"</span>, <span class="code-blue">1</span>);
          `,


          // `
          // &#60;template&#62;
          //   <br><span class="indent"></span>&#60;Header /&#62;
          //   <br><span class="indent"></span>&#60;Services /&#62;
          // <br>&#60;/template&#62;

          // <br><br>&#60;script&#62;
          //   <br><span class="indent"></span>import Header from './components/Header.vue';
          //   <br><span class="indent"></span>import Services from './components/Services.vue';
          //   <br><span class="indent"></span>import './styles/styles.scss';

          //  <br><br><span class="indent"></span> export default {
          //  <br><span class="indent"></span> name: 'App',
          //  <br><span class="indent"></span><span class="indent"></span> components: {
          //  <br><span class="indent"></span><span class="indent"></span> Header,
          //  <br><span class="indent"></span> <span class="indent"></span> Services,
          //  <br>},
          //  <br>}
          // <br>&#60;/script&#62;
          // `,

          // `&#60;!doctype html&#62;
          // <br><span class="indent">&#60;html class="no-js" lang="en-us"&#62;

          // <br><br><span class="indent">&#60;head&#62;
          // <br><span class="indent"><span class="indent">&#60;meta charset="utf-8"&#62;
          // <br><span class="indent"><span class="indent">&#60;title&#62;Elemeno&#60;/title&#62;
          // <br><span class="indent"><span class="indent">&#60;meta name="description" content="Elemeno"&#62;
          // <br><span class="indent">&#60;meta name="viewport" content="width=device-width, initial-scale=1"&#62;
          // `
      ]


      var options = {
          strings: codeExamples,
          typeSpeed: 10,
          startDelay: 1000,
          showCursor: false,
          loop: true,
          contentType: 'html'
        };

          new Typed('#code-editor .code', options);

        }

        
  }
}
</script>
