<template>

<section id="contact">

<div class="container-fluid">

    <div class="section-copy">
    <h3>Contact Us</h3>
    <h5>let's get started</h5>

    <div class="line-sep">
      <img src="../assets/line.png" alt="Line">
    </div>
  </div>


    <div id="footer-form">

    <!-- <form name="contact" method="POST" data-netlify="true"> -->

  <form class="form-inline" action="https://getform.io/f/123af350-b5f3-48a5-b43b-e267619b2bdf" name="contact" method="POST" data-netlify="true">

<div class="flex-form">

    <div class="form-item">
      <div class="form-group">
        <label for="contact-name">Your Name</label>
        <input type="name" class="form-control" id="contact-name" placeholder="John Smith" name="name">
      </div>
      <div class="form-group">
        <label for="contact-email">Email address</label>
        <input type="email" class="form-control" id="contact-email" placeholder="name@example.com" name="email">
      </div>
        <div class="form-group">
        <label for="contact-phone">Phone</label>
        <input type="name" class="form-control" id="contact-phone" placeholder="555-867-5309" name="phone">
      </div>
    </div>

    <div class="form-item">
      <div class="form-group">
        <label for="contact-budget">Budget</label>
        <select class="form-control" id="contact-budget" name="budget">
          <option>Less than $10,000</option>
          <option>$10,000 - $20,000</option>
          <option>$21,000 - $30,000</option>
          <option>$31,000 - $50,000</option>
          <option>$51,000 - $100,000 +</option>
          <option>Not Sure...</option>
        </select>
      </div>
      <div class="form-group">
        <label for="exampleFormControlTextarea1">Description</label>
        <textarea class="form-control" id="exampleFormControlTextarea1" rows="4" name="description"></textarea>
      </div>

    </div>
    
    </div>

       <button id="contact-submit" type="submit" class="btn btn-lmno">Submit</button>

    </form>

    </div>

</div>




 </section>      

</template>

<script>
export default {
  name: 'Contact',
}
</script>
