<template>

 <section id="values">


<div class="container-fluid">


 <div id="shapes-section-1">
      <img src="../assets/circles.png" class="img-fluid" alt="circle shape">
      <img src="../assets/x.png" class="img-fluid" alt="x shape">
      <img src="../assets/triangle.png" class="img-fluid" alt="triangle shape">
   </div>


  <div class="section-copy">
    <h3>Our Values</h3>
    <h5>our general scientific theorem</h5>

    <div class="line-sep">
      <img src="../assets/line.png" alt="Line">
    </div>

    
  <div id="values-wrapper">

      <div @mouseover="boxLine()" class="box-wrapper">
        <div class="box-wrapper-header">
          Commitment
        </div>
        <div class="box-wrapper-body">
          <p>
             Delivering on our commitments is something we take very seriously. We hold ourselves accountable to ensure we delivery with quality on time, every time.
          </p>
        </div>
      </div>

      
      <div class="box-wrapper">
        <div class="box-wrapper-header">
         Authenticity
        </div>
        <div class="box-wrapper-body">
          <p>
          Authenticity breeds trust. Authenticity allows us to inspire one another and makes our work more meaningful, interesting and fun.
          </p>
        </div>
      </div>

      <div class="box-wrapper">
        <div class="box-wrapper-header">
          Innovation
        </div>
        <div class="box-wrapper-body">
          <p>
             We push innovation with every new project, as well as client products, and within ourselves. We value original thinking above all.
          </p>
        </div>
      </div>

      <div class="box-wrapper">
        <div class="box-wrapper-header">
          Excellence
        </div>
        <div class="box-wrapper-body">
          <p>
             We always strive for excellence in everything we touch. We set high standards from our work and from ourselves.
          </p>
        </div>
      </div>
      
      </div>


  </div>

</div>


 </section>      

</template>

<script>

export default {
  name: 'Values',

  methods: {
  
  }
}
</script>
