<template>

 <section id="footer">


<div class="container-fluid d-flex">


  <div class="footer-section">


  <ul>
      <li class="d-none">
        <a role="button" class="btn btn-lmno">
            Contact Us
        </a>
      </li>

      <li>
          <a href="mailto:hello@elemeno.dev"><strong>hello</strong>@elemeno.dev</a>
      </li>

      <li>
        <a href="tel:1-404-913-1161">+1 (404) 913-1161</a>
      </li>

      <li>
        <address>
          300 Colonial Center Parkway, STE 100N<br>
          Roswell, GA 30076
        </address>
      </li>

    </ul>
  </div>

  <div class="footer-section">
    <ul>
      <li>
         <router-link :to="{ path: '/', hash: '#services' }"><a>Services</a></router-link>
      </li>
      <li>
         <router-link :to="{ path: '/', hash: '#our-process' }"><a>Our Process</a></router-link>
      </li>
      <li>
         <router-link :to="{ path: '/', hash: '#values' }"><a>Our Values</a></router-link>
      </li>
      <li>
         <router-link :to="{ path: '/', hash: '#work' }"><a>Work</a></router-link>
      </li>
      <li>
         <router-link :to="{ path: '/', hash: '#speak' }"><a>What We Speak</a></router-link>
      </li>
    </ul>

    </div>

 <div class="footer-section">
    
    <ul>
      <li><a href="https://www.linkedin.com/company/elemeno-development" target="_blank">LinkedIn</a></li>
      <li><a href="#">Legal</a></li>
      <li><a href="#">Careers</a></li>
    </ul>
 </div>

 <div class="footer-section">
   <a href="#"><img src="../assets/hiring.png" class="img-fluid" alt="We're Hiring"></a>
   </div>


  </div>
  

 </section>      

</template>

<script>

export default {
  name: 'Footer',
}
</script>
