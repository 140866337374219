<template>
  <header class="container-fluid">
        <div id="logo">

            <!-- <div v-if="!this.mobile" id="logo-bar-collection">
                <div class="logo-bar"></div>
                <div class="logo-bar"></div>
                <div class="logo-bar"></div>
                <div class="logo-bar"></div>
            </div> -->

            <img v-if="!this.mobile" alt="Elemeno logo" class="img-fluid" src="../assets/logo-color.png">
            <img v-if="this.mobile" alt="Elemeno logo" class="img-fluid" src="../assets/logo-type-mobile.png"> 
        </div>

        <nav>
            <router-link :to="{ path: '/', hash: '#services' }"><a>Services</a></router-link>
            <router-link :to="{ path: '/', hash: '#our-process' }"><a href="#">Our Process</a></router-link>
            <router-link :to="{ path: '/', hash: '#values' }"><a href="#">Our Values</a></router-link>
            <router-link :to="{ path: '/', hash: '#work' }"><a href="#">Work</a></router-link>
            <router-link :to="{ path: '/', hash: '#what-we-speak' }"><a href="#">What We Speak</a></router-link>

             <router-link :to="{ path: '/', hash: '#contact' }"><a role="button" class="btn btn-lmno">
                Contact Us
            </a></router-link>
        </nav>

  </header>
   
</template>

<script>

import { isMobile } from '../utils/mobile';
// import anime from 'animejs';

export default {
  name: 'Header',

  data: function() {
    return {
      mobile: false
    }
  },
  

created() {
    this.mobile = isMobile();
  },

  mounted() {
    //   anime({
    //     targets: '#logo-bar-collection .logo-bar',
    //     translateX: [-100, 0],
    //     delay: anime.stagger(100)
    // });
  },
}
</script>
