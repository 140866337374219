<template>
  <section id="services">
    <div class="container-fluid">
      <div id="shapes-section-1">
        <img src="../assets/circles.png" class="img-fluid" alt="circle shape" />
        <img src="../assets/x.png" class="img-fluid" alt="x shape" />
        <img src="../assets/triangle.png" class="img-fluid" alt="triangle shape" />
      </div>

      <div class="section-copy">
        <h3>Services</h3>
        <h5>what we offer</h5>

        <div class="line-sep">
          <img src="../assets/line.png" alt="Line" />
        </div>
      </div>

      <div id="services-box-wrapper">
        <div class="services-box" id="web-development">
          <img class="img-fluid" src="../assets/icon-web.png" />
          <h3>Web Development</h3>
          <p>
            With an average of 10+ years experience per developer, our team specializes in
            full stack web development from backend, to middleware, to frontend and
            everything in-between. Providing full reliable solutions to your web
            application.
          </p>
        </div>

        <div class="services-box" id="web-design">
          <img class="img-fluid" src="../assets/icon-design.png" />

          <h3>
            Web Design
            <span class="select-box-bg"></span><span class="select-box-bg"></span
            ><span class="select-box-bg"></span><span class="select-box-bg"></span>
          </h3>
          <p>
            Clients tend to make decisions based solely on the visual presentation of your
            company. Professional design conveys trust, quality, and assurance. We employ
            designers with over a decade of experience..
          </p>
        </div>

        <div class="services-box" id="mobile-development">
          <img class="img-fluid" src="../assets/icon-mobile.png" />
          <h3>Mobile Development</h3>
          <p>
            We bring your mobile app to market quickly and reliably. From iOS to Android,
            we take the smart approach, mitigating the need for multiple codebases while
            providing seemless branding across your devices and domains.
          </p>
        </div>

        <div class="services-box" id="product-development">
          <img class="img-fluid" src="../assets/icon-layers.png" />
          <h3>Product Development</h3>
          <p>
            Software and Web Application development is a complex process for widespread
            adoption. Luckily, we employ trusted professionals and specilists in SaaS and
            general product development to meet and exceed your expectations.
          </p>
        </div>

        <div class="services-box" id="fintech-development">
          <img class="img-fluid" src="../assets/icon-card.png" />
          <h3>Fintech Solutions</h3>
          <p>
            From Blockchain to Banking, to Regulatory services, we have a team specialized
            in all areas of fintech, offering insider knowledge to development processes.
          </p>
        </div>

        <div class="services-box" id="quality-assurance">
          <img class="img-fluid" src="../assets/icon-qa.png" />
          <h3>Quality <span class="sqwiggle">Assuranec</span></h3>
          <!-- <h3>Quality <span class="sqwiggle">Assuran</span><span class="text-animation">ec</span></h3> -->
          <p>
             Attention to detail is in our blood.
            Maintaining and conveying quality within your product is paramount. With every step in the chain, you can ensure the
            product you launch is vetted by multiple professional quality assurance
            specialists.
          </p>
        </div>

        <!-- <div id="services-line-wrapper">
      <div class="services-line"></div>
    </div> -->
      </div>
    </div>
  </section>
</template>

<script>
import anime from "animejs";
import Typed from "typed.js";
import $ from "jquery";
import { isMobile } from "../utils/mobile";

export default {
  name: "Services",
  data() {
    return {
      hasAnimatedDesign: false,
      hasAnimatedQA: false,
      mobile: false,
    };
  },

  mounted() {
    this.animateServices();
    this.mobile = isMobile();
  },

  methods: {
    startAnimationDesign() {
      this.hasAnimatedDesign = true;
      this.animateWebDesign();
    },

    startAnimationQA() {
      this.hasAnimatedQA = true;
      this.animateQualityAssurance();
    },

    animateServices() {
      let io_design = new IntersectionObserver((entries) => {
        entries[0].isIntersecting && !this.hasAnimatedDesign
          ? this.startAnimationDesign()
          : "";
      }, {});
      const elem_design = document.getElementById("web-design");
      io_design.observe(elem_design);

      var io_qa = new IntersectionObserver((entries) => {
        entries[0].isIntersecting && !this.hasAnimatedQA ? this.startAnimationQA() : "";
      }, {});
      const elem_qa = document.getElementById("quality-assurance");
      io_qa.observe(elem_qa);
    },

    animateWebDesign() {
      anime({
        targets: "#web-design h3",
        rotate: ["5deg", "0deg"],
        easing: "spring(1, 80, 10, 0)",
        direction: "alternate",
        duration: 500,
        loop: false,
        complete: () => {
          $("#web-design h3").css("border", "none");
          $(".select-box-bg").fadeOut();
        },
      });

      anime({
        targets: "#web-design h3",
        width: this.isMobile ? ["55%", "45%"] : ["55%", "60%"],
        easing: "spring(1, 80, 10, 0)",
        direction: "alternate",
        duration: 300,
        loop: false,
        delay: 500,
      });
    },

    animateQualityAssurance() {
      var options = {
        strings: ["Assuranec", "Assurance"],
        typeSpeed: 400,
        startDelay: 500,
        backSpeed: 200,
        loop: false,
        onComplete: () => {
          $("#quality-assurance .sqwiggle").css("background", "none");
          $("#quality-assurance .typed-cursor").hide();
        },
      };

      new Typed("#quality-assurance .sqwiggle", options);
    },
  },
};
</script>
