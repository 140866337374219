<template>

 <section id="work">


<div class="container-fluid">


 <div id="shapes-section-1">
      <img src="../assets/circles.png" class="img-fluid" alt="circle shape">
      <img src="../assets/x.png" class="img-fluid" alt="x shape">
      <img src="../assets/triangle.png" class="img-fluid" alt="triangle shape">
   </div>


  <div class="section-copy">
    <h3>Our Work</h3>
    <h5>what we've built</h5>

    <div class="line-sep">
      <img src="../assets/line.png" alt="Line">
    </div>


  <div id="work-wrapper">
      <div><img src="../assets/work/metricsflow.png" class="img-fluid" alt="Seismic IoT"></div>
        <div id="work-case">
          <h4>Seismic IoT</h4>
          <h5>metrics/software</h5>
              <p>
                 Seismic approached us with the opportunity to develop both a web based and multiplatform application intended to manage fleets of custom IoT and embedded type systems. 
              </p>
              <p>
                Our approach started with requirements gathering, planning, and quickly turned their ideas into client mockups. The unique challenge to the process was developing an API which provided the needed data and actions that also ran on multiple devices with different architectures and we delivered.
              </p>
          </div>
      </div>
    

     <div id="work-wrapper">
      <div><img src="../assets/work/pipeapp.png" class="img-fluid" alt="Social Pipe"></div>
        <div id="work-case">
          <h4>Social Pipe</h4>
          <h5>social/software</h5>
              <p>
                  Social Pipe is the code name for a new proprietary application developed for an independent client for use as their Social Media Advertisement management application. Built as a cross-platform application as well as for deployment on web and mobile utilizing Electron, ReactJS, and React Native.</p>
          </div>
      </div>
    

      <div id="work-wrapper" class="d-none">
      <div><img src="../assets/work/coinbank.png" class="img-fluid" alt="Coin Bank"></div>
        <div id="work-case">
          <h4>Coin.Bank</h4>
          <h5>fintech/mobile</h5>
              <p>
                  Somer Perspective came to us with a challenge. "We want to build financial software, and we want <em><strong>privacy by default</strong></em>." This sentiment rang true with every member of our team so we were very excited to take on the challenge.
              </p>
              <p>
                Given the privacy and data nerds that we are, we jumped to the opportunity to both work with the client on specs and eagerly await the opportunity to provide a clean user interface with accessible and interactive charts, all while keeping user data as private as possible.
              </p>
              <p>
                We delivered an intuitive and accessible experience all while providing necessary safeguards for user privacy with minimal user information retention. Utilizing React Native as our foundation, we were able to deliver both an iOS and Android app while mitigating the need for maintaining multiple codebases.
              </p>
          </div>
      </div>

      

  </div>

</div>


 </section>      

</template>

<script>

export default {
  name: 'Work',

  methods: {
  
  }
}
</script>
