<template>

 <section id="our-process">

   <div id="shapes-section-2">
      <img src="../assets/circles.png" class="img-fluid" alt="circle shape">
      <img src="../assets/x.png" class="img-fluid" alt="x shape">
      <img src="../assets/triangle.png" class="img-fluid" alt="triangle shape">
   </div>



  <div class="section-copy">
   <h3>Our Process</h3>
   <h5>how we build</h5>

   <div class="line-sep">
     <img src="../assets/line.png" alt="Line">
   </div>



   <div id="process-list">

       <ul>
           <li @click="processChange('discovery')" v-bind:class="{ active: this.view == 'discovery' }">discovery</li> 
           <li @click="processChange('planning')" v-bind:class="{ active: this.view == 'planning' }">planning</li>
           <li @click="processChange('design')" v-bind:class="{ active: this.view == 'design' }">design</li>
           <li @click="processChange('development')" v-bind:class="{ active: this.view == 'development' }">development</li> 
           <li @click="processChange('testing')" v-bind:class="{ active: this.view == 'testing' }">testing</li>
           <li @click="processChange('delivery')" v-bind:class="{ active: this.view == 'delivery' }">delivery</li>
           <li @click="processChange('maintenance')" v-bind:class="{ active: this.view == 'maintenance' }">maintenance</li>

       </ul>
   </div>

    <div id="process-img">
        <img v-if="this.view == 'discovery'" src="../assets/discovery-photo.jpg" class="img-fluid">
        <img v-if="this.view == 'planning'" src="../assets/planning-photo.jpg" class="img-fluid">
        <img v-if="this.view == 'design'" src="../assets/design-photo.jpg" class="img-fluid">
        <img v-if="this.view == 'development'" src="../assets/development-photo.jpg" class="img-fluid">
        <img v-if="this.view == 'testing'" src="../assets/testing-photo.jpg" class="img-fluid">
        <img v-if="this.view == 'delivery'" src="../assets/deploy-photo.jpg" class="img-fluid">
        <img v-if="this.view == 'maintenance'" src="../assets/maintenance-photo.jpg" class="img-fluid">
    </div>


    <!-- discovery -->
   <div v-if="this.view == 'discovery'" id="process-discovery" class="container">
       <h4>Discovery</h4>
       <h5>provide purpose and direction</h5>
       <div class="process-overlay">

       <h4>Discovery</h4>
       <h5>provide purpose and direction</h5>
            <p>Discovery is about learning what you have, what you want, and what you need.</p>

            <p>The Discovery Phase is the first step in the project development process on the road to a success.
                During the discovery phase, we collect data and information about your project, 
                its intended market, and its audience. It allows us to get a well-rounded and in-depth understanding of 
                the goals and scope of your project so that we deliver exceeding results on time.</p>
                   

            <div v-if="mobile" class="process-link-wrapper">
                <div  class="process-link" @click="processChange('planning')">
                    Planning &rarr;
                </div>
            </div>
       </div>
   </div>
  
  
    <!-- planning -->
   <div v-if="this.view == 'planning'" id="process-planning" class="container">
       <h4>Planning</h4>
       <h5>defining objectives</h5>
       <div class="process-overlay">

        <h4>Planning</h4>
       <h5>define objectives</h5>

           <p>Well-defined goals = straightforward execution.</p>
           <p>
               Using the information gathered from the discovery phase, we iterate over a plan while defining scope, deliverables, and timelines. 
               We start with the broad vision of your project and work backwards to define specific milestones. With a plan in place, we assign team memembers whos experience professionaly aligns with the products specific technologies, visions, and goals.
           </p>

             <div v-if="mobile" class="process-link-wrapper">
                <div  class="process-link" @click="processChange('discovery')">
                    &larr; Discovery
                </div>
                <div  class="process-link" @click="processChange('design')">
                    Design &rarr;
                </div>
            </div>

       </div>
   </div>


    <!-- design -->
   <div v-if="this.view == 'design'" id="process-design" class="container">
       <h4>Design</h4>
       <h5>looking good</h5>
       <div class="process-overlay">

        <h4>Design</h4>
       <h5>looking good</h5>
           <p>
               During the design phase, our design professionals utilize modern design tools to provide you with visual representations of the product. We gather feedback and iterate until you are completly satisfied and wow-ed by your future products look and feel.
           </p>
           <p>
               We also provide you with re-usable assets in various formats so you can extend your branding to other mediums no matter who you choose in the future. Whether you have established branding or not, in this phase, we work hard to create your recognizable and lasting appeal.
            </p>

              <div v-if="mobile" class="process-link-wrapper">
                <div  class="process-link" @click="processChange('planning')">
                    &larr; Planning
                </div>
                <div  class="process-link" @click="processChange('development')">
                    Development &rarr;
                </div>
            </div>

       </div>
   </div>

    <!-- development -->
   <div v-if="this.view == 'development'" id="process-development" class="container">
       <h4>Development</h4>
       <h5>from mvp to vip</h5>
       <div class="process-overlay">
             <h4>Development</h4>
       <h5>from mvp to vip</h5>
           <p>
               During the development process, our developers will work within spec and designs to create your fully functional application while providing you with regular testable and presentations of the app. 
           </p>
           <p>
               We start with the minimum viable product and iterate through your feedback to reliably produce an application that perfectly suits your needs and is ready for market. You will be supported with consistant updates and testable solutions to your product on a regular basis so you are never left in the dark on progress or timelines.
           </p>
              <div v-if="mobile" class="process-link-wrapper">
                <div  class="process-link" @click="processChange('design')">
                    &larr; Design
                </div>
                <div  class="process-link" @click="processChange('testing')">
                    Testing &rarr;
                </div>
            </div>
       </div>
   </div>


    <!-- testing -->
   <div v-if="this.view == 'testing'" id="process-testing" class="container">
       <h4>Testing</h4>
       <h5>prioritizing quality solutions</h5>
       <div class="process-overlay">
           <h4>Testing</h4>
            <h5>prioritizing quality solutions</h5>
           <p>
               The Quality Assurance phase takes place after initial development is finalized. This is an important step in the process to ensure that you or your company is getting the best quality product.  
           </p>

           <p>
               During this phase, our quality assurance professionals create test scenerios to weed out any issues or edge-cases in which your application could not ideally be consumed. After QA reporting, our developers will provide any possible adjustments to your product to make sure you are getting the best possible solution.
           </p>

            <div v-if="mobile" class="process-link-wrapper">
            <div  class="process-link" @click="processChange('development')">
                &larr; Development
            </div>
            <div  class="process-link" @click="processChange('delivery')">
                Delivery &rarr;
            </div>
        </div>
       </div>
   </div>


      
    <!-- delivery -->
   <div v-if="this.view == 'delivery'" id="process-delivery" class="container">
       <h4>Delivery</h4>
       <h5>time to ship</h5>
       <div class="process-overlay">
            <h4>Delivery</h4>
       <h5>time to ship</h5>
           
           <p>
               The most exciting phase of your project is the product launch. During this phase, we make good on all deliverables outlined during the planning phase and launch your solution to market. 
           </p>
           <p>
              Depending on your launch strategy, we provide the the assistance on launching your product to the world.  We also provide you with all source and assets to your product. 
           </p>
   
          <div v-if="mobile" class="process-link-wrapper">
                <div  class="process-link" @click="processChange('testing')">
                    &larr; Testing
                </div>
                <div  class="process-link" @click="processChange('maintenance')">
                    Maintenance &rarr;
                </div>
            </div>
        </div>
   </div>

   
    <!-- maintenance -->
   <div v-if="this.view == 'maintenance'" id="process-maintenance" class="container">
       <h4>Maintenance</h4>
       <h5>let's take it further</h5>
       <div class="process-overlay">
           <h4>Maintenance</h4>
            <h5>let's take it further</h5>
           <p>
               If needed, we are more than happy to maintain your product and provide future upgrades. 
           </p>
           <p>
               Software often needs to be upgraded or integrated with new systems and during this phase, our team is here to help. From maintenance, to new features, to new a new fresh design, we can maintain your application well into the future.
            </p>

            <div v-if="mobile" class="process-link-wrapper">
                <div  class="process-link" @click="processChange('delivery')">
                    &larr; Delivery
                </div>
            </div>
       </div>
   </div>

  </div>

 </section>      

</template>

<script>

import { isMobile } from '../utils/mobile';

export default {
  name: 'OurProcess',
  

  data() {
      return {
        view: 'discovery',
        mobile: false

      }
  },

  created() {
        this.mobile = isMobile();
  },

  methods: {
      processChange(v) {

          this.view = v;
      }


  }
}
</script>
