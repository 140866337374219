import { createWebHistory, createRouter } from "vue-router";
// import WhyChooseUs from "@/components/WhyChooseUs.vue";
import $ from 'jquery'

const routes = [];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior (to) {
        if (to.hash) {
        return $('html,body').stop().animate({scrollTop: $(to.hash).offset().top }, 500);
        } else {
        return $('html,body').stop().animate({scrollTop: 0 }, 500);
        }
    },
});

export default router;